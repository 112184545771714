import Vue from 'vue'
import Vuex from 'vuex'
import { IState, Mutations, Actions, SessionKeys, ProgressPanelState } from './models'
import scansModule from './modules/scansModule'
import { associates, companies, IAssociate, projects, AssociateRoles, ICompany, GssiRoles, LicenseType } from '@/api'
import { baseUrl, HeaderKeys, IErrorResponse, setHeaders, updateBaseUrl } from '@/api/common'
import scansFilterModule from './modules/scansFilterModule'
import router from '@/router'

Vue.use(Vuex)
const initialState = {
  terms: "",
  conditions: "",
  associate: {},
  accounts: [],
  company: {
    id: 0,
    account: {
      cao: {},
      license_info: {}
    },
    projects: [],
    groups: [],
    associates: []
  },
  error: {
    display: false,
    message: undefined
  },
  toast: {
    icon: {
      name: '',
      color: ''
    },
    text: '',
    isBottom: false
  },
  isLoading: false,
  forceLoading: false,
  supportDialog: false,
  hideNavDrawer: false,
  progressPanel: {
    text: "",
    state: ProgressPanelState.Dismissed,
    progress: 0
  },
  fsnProcessingToast: 0
}
export default new Vuex.Store({
  state: (): IState => ({ ...initialState } as any),
  getters: {
    associateName: (state): string => {
      return (state.associate.first_name || '') + ' ' + (state.associate.last_name || '')
    },
    associateRoles: (state): Set<AssociateRoles | GssiRoles> => {
      return new Set(state.associate.roles)
    },
    hasPermissionTo: (_, getters) => (role: AssociateRoles | GssiRoles) => {
      return getters.associateRoles.has(role)
    },
    isGssiAdmin: (state): boolean => {
      return state?.associate?.company?.name === "GSSI"
    },
    licenseType: (state): LicenseType | undefined => {
      // return LicenseType.FreeTrial
      return state.company?.account?.license_info?.license_id || state.company?.account?.cao?.company?.license_id
    },
    freeTrailDaysLeft: (state): number => {
      const renewDate: any = new Date(
        state.company?.account?.license_info.renewal_date
      );
      const today: any = new Date();
      const t2 = renewDate.getTime();
      const t1 = today.getTime();

      return Math.floor((t2 - t1) / (24 * 3600 * 1000));
    }
  },
  actions: {
    async getTermsAndAgreement({ state, getters, dispatch, commit }) {
      try {
        const terms = (await companies.nlc.getTermsAndConditions()).data.content;
        const agreement = (await companies.nlc.getUserAgreement()).data.content;
        commit(Mutations.SetTermsAndAgreement, {
          terms,
          agreement
        })
      } catch (e) {
        // console.log(e)
      }
    },
    async initPublic({ state, getters, dispatch, commit }, token: string) {
      try {
        if (!token) {
          throw 'Invalid URL'
        }
        updateBaseUrl({ public: true })
        setHeaders([
          {
            key: HeaderKeys.AuthToken,
            value: token
          }
        ])
      } catch (e) {
        dispatch(Actions.DisplayError, e)
      }

    },
    async init({ state, getters, dispatch, commit }, authtoken: string): Promise<{ initialized: boolean, msg?: unknown }> {
      if (!authtoken) {
        const authtoken = localStorage.getItem(SessionKeys.AuthToken)
        if (authtoken) {
          return dispatch(Actions.InitUser, authtoken)
        } else {
          return {
            initialized: false
          }
        }
      }

      else {
        try {
          // const response = await associates.getAccessToken(code)
          // const authtoken = response?.data?.access_token || (response as any).access_token
          localStorage.setItem(SessionKeys.AuthToken, authtoken)
          return dispatch(Actions.InitUser, authtoken)
        } catch (e) {
          return {
            initialized: false
          }
        }
      }
    },

    async initUser({ state, getters, dispatch, commit }, authtoken: string): Promise<{ initialized: boolean, msg?: unknown }> {
      setHeaders([
        {
          key: HeaderKeys.AuthToken,
          value: authtoken
        }
      ])
      try {
        const user = await dispatch(Actions.GetUserInfo)
        dispatch(Actions.GetCompany, user.company)

        return {
          initialized: true
        }
      } catch (error: any) {
        if (error?.response?.data?.status?.message?.sessionStatus === 'rejected') {
          dispatch(Actions.Logout)
          return {} as any
        }
        return {
          initialized: false,
          msg: getErrorMessage(error)
        }
      }
    },
    async getUserInfo({ state, getters, dispatch, commit }, authtoken: string): Promise<IAssociate> {
      const response = (await associates.getUserInfo())
      const user = { ...response.data[0], action: response.status?.status }
      if (!user.id) {
        throw { error: { response: user } }
      }
      commit(Mutations.SetAssociate, user)
      return user
    },


    async getCompany({ state, getters, dispatch, commit }, company: ICompany): Promise<void> {
      await commit(Mutations.SetCompany, company)
      await dispatch(Actions.GetCompanyAccount)
      await dispatch(Actions.GetCompanyProjects)
      await dispatch(Actions.GetCompanyGroups)
      await dispatch(Actions.GetCompanyAssociates)
    },
    async getCompanyAccount({ state, getters, dispatch, commit }): Promise<void> {
      try {
        const account = (await companies.company.manageAccount(state.company?.id)).data
        commit(Mutations.SetCompany, { ...state.company, ...{ account } });
      } catch (e) {
        dispatch(Actions.DisplayError, e)
      }
    },
    async getCompanyProjects({ state, getters, dispatch, commit }): Promise<void> {
      try {
        const { id } = state.company
        const projectsData = (await projects.getByCompany(id)).data
        commit(Mutations.SetCompany, { ...state.company, ...{ projects: projectsData } });
      } catch (e) {
        dispatch(Actions.DisplayError, e)
      }
    },
    async getCompanyGroups({ state, getters, dispatch, commit }): Promise<void> {
      if (!getters.isGssiAdmin && !getters.hasPermissionTo(AssociateRoles.ManageGroups)) {
        return
      }
      try {
        const { id } = state.company
        const groupsData = (await associates.getAllGroupsByCompany(id)).data
        commit(Mutations.SetCompany, { ...state.company, ...{ groups: groupsData } });
      } catch (e) {
        dispatch(Actions.DisplayError, e)
      }
    },
    async getCompanyAssociates({ state, getters, dispatch, commit }): Promise<void> {
      if (!getters.isGssiAdmin) {
        if (!getters.hasPermissionTo(AssociateRoles.ManageAccount) || !getters.hasPermissionTo(AssociateRoles.ManageGroups))
        return
      }
      try {
        const { id } = state.company
        const associatesData = (await associates.getAllByCompany(id)).data
        commit(Mutations.SetCompany, { ...state.company, ...{ associates: associatesData } });
        // setting current user
        if (Array.isArray(associatesData)) {
          const associate = associatesData?.find(a => a.id === state.associate.id)
          if (associate) {
            commit(Mutations.SetAssociate, { ...state.associate, ...associate })
          }
        }
      } catch (e) {
        dispatch(Actions.DisplayError, e)
      }
    },

    async logout({ state, getters, dispatch, commit }, routeToHome = true): Promise<void> {
      try {
        associates.logout()
        dispatch(Actions.ClearSession);
        if (routeToHome) {
          router.push("/").catch((e) => {
            return
          });
        }
      } catch (e) {
        dispatch(Actions.DisplayError, e)
      }
    },
    async clearSession({ state, getters, dispatch, commit }) {
      setHeaders([
        {
          key: HeaderKeys.AuthToken,
          value: ''
        }
      ])
      localStorage.clear()
      commit(Mutations.ResetState)
    },

    displayError({ state, getters, dispatch, commit }, error: string | IErrorResponse) {
      console.log(error)
      const msg = getErrorMessage(error)
      if (msg === "The incoming token has expired" ||
        msg === "Unauthorized" ||
        msg === "Invalid Auth Token") {
        // msg === "Unauthorised User Action" ||
        dispatch(Actions.ClearSession);
      } else {
        commit(Mutations.SetError, msg)
      }
    },
  },


  mutations: {
    resetState(state) {
      Object.assign(state, initialState)
    },
    setAssociate(state, associate: IAssociate) {
      state.associate = associate
    },
    setCompany(state, company) {
      state.company = company
    },
    setError(state, message: string) {
      state.error = {
        message,
        display: true
      }
    },
    clearError(state) {
      state.error = {
        message: '',
        display: false
      }
    },
    setToast(state, { text, icon, isBottom }) {
      state.toast = {
        text,
        icon: (icon || initialState.toast.icon),
        isBottom: !!isBottom
      }
    },
    setProgressPanel(state, progressPanel) {
      state.progressPanel = progressPanel
    },
    setIsLoading(state, val) {
      state.isLoading = val
    },
    setForceLoading(state, val) {
      state.forceLoading = val
    },
    setSupportDialog(state, val) {
      state.supportDialog = val
    },
    setHideNavDrawer(state, val) {
      state.hideNavDrawer = val
    },
    setTermsAndAgreement(state, { terms, agreement }) {
      state.terms = terms
      state.agreement = agreement
    },
    setFSNProcessingToast(state, val) {
      state.fsnProcessingToast = val
    }
  },
  modules: {
    scans: scansModule,
    scansFilter: scansFilterModule
  }
})

// helpers 
export const getErrorMessage = (error: any): string => {
  let msg = "Service Error"
  if (typeof error === 'string') {
    msg = error
  } else {
    msg =
      error?.response?.data?.message?.message ||
      error?.response?.data?.message ||
      error?.response?.data?.status?.message?.message ||
      error?.response?.data?.status?.message ||
      msg
  }
  return msg
}
